import "../App.css";
import logo from "../assets/logo.png";
import moment from "moment-timezone";
import io from "socket.io-client";
import { makeStyles } from "@mui/styles";
import Blocked from "../components/Blocked";
import Carousel from "react-material-ui-carousel";
import MarketClose from "../components/MarketClose";
import { IoIosArrowDropdown } from "react-icons/io";
import bullionStats from "../assets/bullioStats.png";
import { useEffect, useMemo, useState } from "react";
import { commodityCalculation } from "../components/constants";
import EconomicNewsModal from "../components/EconomicNewsModal";
import SubscriptionExpired from "../components/SubscriptionExpired";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    display: "flex",
    width: "100vw",
    height: "100dvh",
    background: "#191919",
    color: "#FFFFFF",
    boxSizing: "border-box",
    padding: "1.5rem",
    gap: 15,
  },
  leftSide: {
    height: "100%",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1.5rem",
  },
  logo: {
    height: "auto",
    width: "100%",
    height: "28%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImg: {
    width: "55%",
    height: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  spotRate: {
    borderRadius: "10px",
    height: "22%",
    width: "100%",
    background: "#2F3034",
    display: "flex",
    padding: "1.5em",
    alignItems: "center",
    boxSizing: "border-box",
  },
  spotRateGoldBox: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  askOrBuyBox: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  dollarBox: {
    padding: "0px 6px 0px 6px",
    color: "#FFFFFF",
    fontSize: ".8vw",
    background: "#A18D50",
    borderRadius: "5px",
  },
  spotRateGoldValueBox: {
    border: "1px solid #948B5C",
    borderRadius: "5px",
    width: "10.5vw",
    height: "8.3vh",
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.5s ease",
    justifyContent: "center",
  },
  highLowPart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10%",
    width: "100%",
    gap: "2rem",
  },
  highLowBox: {
    background: "#2F3034",
    borderRadius: "10px",
    width: "33%",
    height: "10vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },
  stat: {
    height: "auto",
    width: "100%",
    height: "13%",
    color: "#69580E",
    background: "#2F3034",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "1rem",
    boxSizing: "border-box",
  },
  bullionStatsImg: {
    width: "10vw",
    height: "auto",
  },
  rightSide: {
    height: "100%",
    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  banner: {
    height: "28%",
    width: "100%",
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    borderRadius: "10px",
  },
  carousel: {
    height: "auto",
    width: "60%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  },
  bannerImg: {
    width: "100%",
    boxSizing: "border-box",
    height: "26.5vh",
    objectFit: "cover",
    borderRadius: "10px",
  },
  time: {
    height: "100%",
    width: "40%",
    background: "#2F3034",
    borderRadius: "10px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
  },
  commodity: {
    height: "59%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  table: {
    gridTemplateColumns: "1fr",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
  },
  tableHeader: {
    backgroundColor: "#2F3034",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#A69668",
    height: "16%",
  },
  tableContent: {
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar
    },
    display: "flex",
    flexDirection: "column",
    overflowX: "scroll",
    height: "83%",
    color: "#000000",
    gap: "10px",
  },
  tableRow: {
    minHeight: "18%",
    display: "flex",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    fontSize: "2vw",
  },
  updates: {
    display: "flex",
    height: "8%",
    width: "100%",
    color: "#FFFFFF",
  },
  updatesHeader: {
    background: "#2F3034",
    color: "#FFFFFF",
    minWidth: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px 0 0 5px",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#222836",
    width: "80%",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [goldNews, setGoldNews] = useState({});

  ////////////Block//////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };
  ///////////////////// SubscriptionExpierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();
  ///////////////////// SubscriptionExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()?.tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now?.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      window.location.reload();
      // handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };
  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handelFindBanners();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };

  const buyersColor = "#5D9EE2";
  const sellersColor = "#DE5B56";

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  const formatDate = () => moment(new Date()).format("DD MMM YYYY");
  const formatDay = () => moment(new Date()).format("dddd");
  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div
      style={{ height: "100vh" }}
      className={(openSubscriptionExpierd || openBlocked) && classes.blur}
    >
      <Box className={classes.mainBody}>
        {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
        <Box className={classes.leftSide}>
          <Box className={classes.logo}>
            <img className={classes.logoImg} src={logo} alt="logo" />
            <Typography sx={{ fontSize: "1.6vw" }}>www.bmggold.com</Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "2.3vw",
                fontWeight: "bold",
                color: "#A18D50",
              }}
            >
              SPOT RATES
            </Typography>
          </Box>
          <Box className={classes.spotRate}>
            <Typography
              sx={{
                fontSize: "2.2vw",
                fontWeight: "bold",
                mb: -5,
              }}
            >
              GOLD
            </Typography>

            <Box className={classes.spotRateGoldBox}>
              <Box className={classes.askOrBuyBox}>
                <Typography className={classes.dollarBox}>$</Typography>
                <Typography sx={{ fontSize: "1.6vw", fontWeight: "bold" }}>
                  {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                </Typography>
                <Typography style={{ fontSize: "1.5vw" }}>oz</Typography>
              </Box>

              <Box
                className={classes.spotRateGoldValueBox}
                sx={{
                  background:
                    Number(gold?.cur?.bid) < Number(gold?.pre?.bid)
                      ? "#DE5B56"
                      : Number(gold?.cur?.bid) > Number(gold?.pre?.bid)
                      ? "#5A9EE4"
                      : "#191919",
                  px: 1.5,
                }}
              >
                <Typography sx={{ fontSize: "2.7vw", fontWeight: "bold" }}>
                  {(!gold?.cur?.bid
                    ? 0
                    : Number(spread.goldBidSpread) + Number(gold?.cur?.bid)
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.spotRateGoldBox}>
              <Box className={classes.askOrBuyBox}>
                <Typography className={classes.dollarBox}>$</Typography>
                <Typography sx={{ fontSize: "1.6vw", fontWeight: "bold" }}>
                  {displayBidOrBuy?.askOrSell?.toUpperCase()}
                </Typography>
                <Typography style={{ fontSize: "1.5vw" }}>oz</Typography>
              </Box>

              <Box
                className={classes.spotRateGoldValueBox}
                sx={{
                  background:
                    Number(gold?.cur?.ask) < Number(gold?.pre?.ask)
                      ? "#DE5B56"
                      : Number(gold?.cur?.ask) > Number(gold?.pre?.ask)
                      ? "#5A9EE4"
                      : "#191919",
                  px: 1.5,
                }}
              >
                <Typography sx={{ fontSize: "2.7vw", fontWeight: "bold" }}>
                  {(!gold?.cur?.ask
                    ? 0
                    : Number(spread.goldAskSpread) + Number(gold?.cur?.ask)
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.highLowPart}>
            <Box className={classes.highLowBox}>
              <IoIosArrowDropdown
                size={30}
                style={{ alignSelf: "end", marginBottom: "20px" }}
              />
              <Box>
                <Typography sx={{ color: "#DC5B59", fontSize: "1vw" }}>
                  Low
                </Typography>
                <Typography style={{ fontSize: "1.7vw" }}>
                  {(!gold?.cur?.lowPrice
                    ? 0
                    : Number(spread.goldLowSpread) + Number(gold?.cur?.lowPrice)
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.highLowBox}>
              <IoIosArrowDropdown
                size={30}
                style={{ alignSelf: "end", marginBottom: "20px" }}
              />
              <Box>
                <Typography sx={{ color: "#599FE4", fontSize: "1vw" }}>
                  High
                </Typography>
                <Typography style={{ fontSize: "1.7vw" }}>
                  {(!gold?.cur?.highPrice
                    ? 0
                    : Number(spread.goldHighSpread) +
                      Number(gold?.cur?.highPrice)
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.stat}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                px: 5,
                py: 1,
                boxSizing: "border-box",
              }}
            >
              <Typography sx={{ fontSize: "0.9vw", color: "#5D9EE2" }}>
                BUYERS
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.9vw",
                  color:
                    Number(ratio?.chgValue.replace("%", "")) >= 0
                      ? "#5D9EE2"
                      : "#D03933",
                }}
              >
                {ratio?.chgValue}
              </Typography>
              <Typography sx={{ fontSize: "0.9vw", color: "#DE5B56" }}>
                SELLERS
              </Typography>
            </Box>
            <Box sx={{ width: "90%", px: 4 }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                style={progressStyle}
                sx={{
                  "& .MuiLinearProgress-bar": barStyle,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                px: 7,
                py: 1,
                boxSizing: "border-box",
              }}
            >
              <Typography sx={{ fontSize: "0.8vw", color: "#5D9EE2" }}>
                {ratio?.Buyers}
              </Typography>
              <Box sx={{ mt: 1 }}>
                <a href="https://www.bullionstats.com/">
                  <img
                    className={classes.bullionStatsImg}
                    src={bullionStats}
                    alt="bullionStats"
                  />
                </a>
              </Box>
              <Typography sx={{ fontSize: "0.9vw", color: "#DE5B56" }}>
                {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.rightSide}>
          <Box className={classes.banner}>
            <Carousel
              animation="fade"
              navButtonsAlwaysVisible={false}
              interval={10000}
              indicatorContainerProps={{
                style: {
                  display: "none",
                },
              }}
              className={classes.carousel}
              transitionTime={3000}
              autoPlay
              duration={2000}
            >
              {banners?.map((banner, i) => (
                <img
                  className={classes.bannerImg}
                  src={banner.imageUrl}
                  alt="banner"
                />
              ))}
            </Carousel>
            <Box className={classes.time}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "3vw",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mb: -2,
                  }}
                >
                  {formatTime()
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
                <br />
                <Typography
                  sx={{
                    fontSize: "2.1vw",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mb: -1,
                  }}
                >
                  {formatDay()
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
                <Typography
                  sx={{
                    letterSpacing: "1px",
                    fontSize: "2vw",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {formatDate()
                    .toUpperCase()
                    .split(" ")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.commodity}>
            <Box className={classes.table}>
              <Box className={classes.tableHeader}>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    fontSize: "2.3vw",
                    fontWeight: "bold",
                  }}
                >
                  COMMODITY
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: 2,
                    fontSize: "2.3vw",
                    fontWeight: "bold",
                  }}
                >
                  WEIGHT
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: 4,
                    alignItems: "center",
                    fontSize: "2.3vw",
                    fontWeight: "bold",
                  }}
                >
                  {displayBidOrBuy?.askOrSell?.toUpperCase()}
                  <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
              </Box>

              <Box className={classes.tableContent}>
                {commodities?.map((commodity, idx) => {
                  const words = commodity.commodity_title.split(" ");
                  return (
                    <Box
                      key={idx}
                      className={classes.tableRow}
                      sx={{
                        background: "#F5F5F5",
                      }}
                    >
                      <Typography
                        className={classes.tableRowColumn}
                        sx={{
                          fontSize: "1.7vw",
                          fontWeight: "bold",
                          paddingLeft: { lg: "1.7vw", md: "2.8vw" },
                        }}
                      >
                        {commodity.display_metal ? (
                          <span
                            style={{
                              fontSize: "2.7vw",
                              fontWeight: "bold",
                            }}
                          >
                            {commodity.display_metal}
                          </span>
                        ) : (
                          <>
                            {words.map((word, index) => {
                              if (index === 0) {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "2.7vw",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {word}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "1.3vw",
                                      marginTop: "0.2rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    &nbsp;{word}
                                  </span>
                                );
                              }
                            })}
                            &nbsp;
                            {commodity.unitLabel === "TTB"
                              ? ""
                              : commodity.displayPurity}
                          </>
                        )}
                      </Typography>
                      <Box
                        className={classes.tableRowColumn}
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "2.7vw",
                            fontWeight: "bold",
                          }}
                        >
                          {commodity.unit}
                          {commodity.unitLabel}
                        </Typography>
                      </Box>
                      <Box
                        className={classes.tableRowColumn}
                        sx={{
                          justifyContent: "left",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2.7vw",
                            fontWeight: "bold",
                            paddingLeft: { lg: "6vw", md: "4.5vw" },
                          }}
                        >
                          {!gold?.cur?.bid
                            ? "0.00"
                            : commodityCalculation(
                                commodity.commodity_title === "Silver"
                                  ? silver?.cur?.ask
                                  : gold?.cur.ask,
                                commodity.commodity_title === "Silver"
                                  ? spread.silverAskSpread
                                  : spread.goldAskSpread,
                                commodity.premium,
                                3.674,
                                commodity.purity,
                                commodity.unit,
                                commodity.unitLabel === "TTB"
                                  ? 116.64
                                  : commodity.unitLabel === "KG"
                                  ? 1000
                                  : commodity.unitLabel === "OZ"
                                  ? 31.1
                                  : commodity.unitLabel === "TOLA"
                                  ? 11.7
                                  : 1,
                                commodity.charges
                              )}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box className={classes.updates}>
            <Box className={classes.updatesHeader}>
              <Typography
                sx={{
                  fontSize: "1.5vw",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                BMG NEWS
              </Typography>
            </Box>
            <Box className={classes.updatesContent} sx={{ minHeight: "100%" }}>
              <marquee
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {!adminData?.isNewsEnable &&
                  Object.keys(goldNews).length > 0 ? (
                    <Box
                      style={{
                        fontSize: "1.5vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        Gold Price News
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                    </Box>
                  ) : (
                    news?.map((item, index) => {
                      return (
                        <Box
                          key={item._id}
                          style={{
                            fontSize: "1.5vw",
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.newsTitle}
                          </span>
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <span>{item.newsDetails}</span>
                          <span
                            style={{
                              paddingRight: "50px",
                              paddingLeft: "50px",
                            }}
                          >
                            {index === news.length - 1 ? "" : "|"}
                          </span>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </marquee>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Home;
